export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
    action: 'read',
    resource: 'Dashboard',
  },
  {
    title: 'Venue',
    icon: 'BookIcon',
    children: [
      {
        title: 'Events',
        route: 'events',
        icon: 'FileTextIcon',
        action: 'read',
        resource: 'Events',
      },
      {
        title: 'Companies',
        route: 'companies',
        icon: 'MapPinIcon',
        action: 'read',
        resource: 'Companies',
      },
    ],
  },
  {
    title: 'Config',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'SCA Acces',
        route: 'aforoacces',
        icon: 'ArrowRightCircleIcon',
        action: 'read',
        resource: 'AforoAccess',
      },
      {
        title: 'SCA Door',
        route: 'aforodoor',
        icon: 'LogInIcon',
        action: 'read',
        resource: 'AforoDoor',
      },
      {
        title: 'Users',
        route: 'users',
        icon: 'UserIcon',
        action: 'read',
        resource: 'Users',
      },
      {
        title: 'User Group',
        route: 'user-group',
        icon: 'UsersIcon',
        action: 'read',
        resource: 'UserGroup',
      },
    ],
  },
]
